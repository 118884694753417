<script setup>
</script>

<template>
  <div class="finish">
    <h1>Make-up FLOW 体験サイト</h1>
    <hr>
    <div class="comment">
      <p>化粧フローチャートの作成お疲れ様でした．体験はこちらで終了となります．</p>
      <p>Make-up FLOWにご興味を抱いてくださったこと感謝申し上げます．</p>
    </div>
  </div>
</template>

<style>
@import url("https://fonts.googleapis.com/css?family=M+PLUS+Rounded+1c");
@import url("https://fonts.googleapis.com/css?family=Noto+Sans+JP");
.finish {
  width: 800px;
  height: 250px;
  margin: 5px auto;
  border: 3px solid #3cb371;
  border-radius: 30px;
  font-family: "Noto Sans JP"; /* "M PLUS Rounded 1c" */
}
hr {
  border-top: 3px solid #3cb371;
}
</style>